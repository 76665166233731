import Banner from '@/components/Home/Banner';
import Content from '@/components/Home/Content';
import EventSummary from '@/components/Home/EventSummary';
import HeroSection from '@/components/Home/HeroSection';
import NewsSummary from '@/components/Home/NewsSummary';
import MainLayout from '@/layouts/MainLayout';
import { GetStaticProps } from 'next';

const Home = () => {
  return (
    <MainLayout>
      <Banner />
      <Content />
      <NewsSummary />
      <EventSummary />
      <HeroSection />
    </MainLayout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};

export default Home;
