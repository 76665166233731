import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';

const LeftContent = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [clipHeight, setClipHeight] = useState(883);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);
  const videoRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // 비디오 요소에 대한 참조 추가
  const videoElementRef = useRef<HTMLVideoElement>(null);
  // 비디오 재생 상태 추가
  const [isPlaying, setIsPlaying] = useState(true);
  // 브라우저 환경 확인을 위한 상태 추가
  const [isBrowser, setIsBrowser] = useState(false);

  // 브라우저 환경 확인
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  // 비디오 재생/일시정지 토글 함수
  const togglePlayPause = () => {
    if (videoElementRef.current) {
      if (isPlaying) {
        videoElementRef.current.pause();
      } else {
        videoElementRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // 컴포넌트가 마운트될 때 비디오 자동 재생
  useEffect(() => {
    if (videoElementRef.current) {
      videoElementRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.log('자동 재생 실패:', error);
          setIsPlaying(false);
        });
    }
  }, [isBrowser]);

  useEffect(() => {
    const checkScreenSize = () => {
      if (!isBrowser) return;

      const screenWidth = window.innerWidth;
      setIsLargeScreen(screenWidth >= 1280);

      if (!containerRef.current) return;

      if (screenWidth < 1280) {
        // xl 브레이크포인트
        containerRef.current.style.width = '100%';
      } else {
        containerRef.current.style.width = '100%';
      }
    };

    checkScreenSize();
    if (isBrowser) {
      window.addEventListener('resize', checkScreenSize);
      return () => window.removeEventListener('resize', checkScreenSize);
    }
  }, [isBrowser]);

  useEffect(() => {
    if (!isLargeScreen || !isBrowser) return;

    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const parentTop = rect.top;
        const parentLeft = rect.left;

        // RightContent의 전체 높이
        const totalHeight = 2000;
        // 비디오의 전체 높이
        const videoHeight = 883;
        // 스크롤이 시작되는 위치를 60px 더 아래로 조정
        const scrollStartPosition = totalHeight - videoHeight / 2 + 60;

        if (window.scrollY > 0) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }

        if (isScrolled) {
          setScrollY(80);
          setLeftPosition(parentLeft);
        }

        if (parentTop <= 80 && window.scrollY <= scrollStartPosition) {
          // 스크롤 시작 전
          setScrollY(80);
          setClipHeight(videoHeight);
          setIsScrolledToEnd(false);
        } else if (parentTop > 80) {
          // 최상단
          setScrollY(parentTop);
          setClipHeight(videoHeight);
          setIsScrolledToEnd(false);
        } else if (window.scrollY > scrollStartPosition) {
          // 스크롤 진행
          setScrollY(80);
          const scrollProgress = Math.max(
            0,
            window.scrollY - scrollStartPosition,
          );
          const newHeight = Math.max(0, videoHeight - scrollProgress);
          setClipHeight(newHeight);
          setIsScrolledToEnd(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLargeScreen, isScrolled, isBrowser]);

  return (
    <div ref={containerRef} className="w-full">
      <div
        ref={videoRef}
        className="relative w-full aspect-[699/943] overflow-hidden rounded-[15px]"
        style={{
          position: isLargeScreen && isScrolled ? 'fixed' : 'relative',
          top: isLargeScreen && isScrolled ? scrollY : 0,
          width:
            isLargeScreen && isScrolled
              ? containerRef.current?.offsetWidth
              : '100%',
          maxWidth: isLargeScreen ? '699px' : '100%',
          left:
            isLargeScreen && isScrolled
              ? containerRef.current?.getBoundingClientRect().left
              : isLargeScreen
                ? '50%'
                : '0',
          transform: isLargeScreen && !isScrolled ? 'translateX(-50%)' : 'none',
          height: isLargeScreen && isScrolled ? `${clipHeight}px` : '100%',
          zIndex: 10,
          borderRadius: isScrolledToEnd ? '15px 15px 0 0' : '15px',
        }}
        onClick={togglePlayPause}
      >
        <div
          className="absolute inset-0 bg-white"
          style={{
            borderRadius: isScrolledToEnd ? '15px 15px 0 0' : '15px',
          }}
        />
        <video
          ref={videoElementRef}
          className="absolute w-full h-[883px] object-cover object-top"
          style={{
            clipPath: isScrolledToEnd
              ? 'inset(0 0 60px 0)'
              : 'inset(0 0 60px 0 round 15px)',
          }}
          muted
          loop
          playsInline
          autoPlay
        >
          {isBrowser && (
            <source
              src={`${window.location.origin}/uploads/main-video.mp4`}
              type="video/mp4"
            />
          )}
        </video>

        {!isPlaying && (
          <button
            onClick={togglePlayPause}
            className="absolute top-[30px] right-[30px] rounded-full shadow-md"
            style={{ backgroundColor: 'transparent', color: 'white' }}
          >
            <img
              src="/images/icon/play_circle.svg"
              alt="Play"
              className="w-6 h-6"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default LeftContent;
