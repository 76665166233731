import React from 'react';

interface TranslatedTextProps {
  text: string;
}

const TranslatedText = ({ text }: TranslatedTextProps) => {
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export default TranslatedText;
