/* To use localStorage this function should be imported dynamically */

export const storeDate = (key: string) => {
  localStorage.setItem(key, String(new Date().getDate()));
};

/**
 * check is `key` passed one day or not
 */
export const isDateChanged = (key: string) => {
  const date = localStorage.getItem(key);

  if (Number(date) !== Number(new Date().getDate())) {
    return true;
  } else {
    return false;
  }
};

/**
 * convert date to string format
 * @param startDate
 * @param endDate
 */
export const formatDateRange = (startDate: string, endDate: string) => {
  // Handle empty dates
  if (!startDate) return '';

  const start = new Date(startDate);

  // If no end date, just return start date
  if (!endDate) {
    return `${start.toLocaleString('en', { month: 'long' })} ${start.getDate()}, ${start.getFullYear()}`;
  }

  const end = new Date(endDate);

  // If dates are same
  if (start.getTime() === end.getTime()) {
    return `${start.toLocaleString('en', { month: 'long' })} ${start.getDate()}, ${start.getFullYear()}`;
  }

  // If dates are in same month and year
  if (
    start.getMonth() === end.getMonth() &&
    start.getFullYear() === end.getFullYear()
  ) {
    return `${start.toLocaleString('en', { month: 'long' })} ${start.getDate()} – ${end.getDate()}, ${start.getFullYear()}`;
  }

  // If dates are in different months but same year
  if (start.getFullYear() === end.getFullYear()) {
    return `${start.toLocaleString('en', { month: 'long' })} ${start.getDate()} – ${end.toLocaleString('en', { month: 'long' })} ${end.getDate()}, ${start.getFullYear()}`;
  }

  // If dates are in different years
  return `${start.toLocaleString('en', { month: 'long' })} ${start.getDate()}, ${start.getFullYear()} – ${end.toLocaleString('en', { month: 'long' })} ${end.getDate()}, ${end.getFullYear()}`;
};
