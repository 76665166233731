import useSWR from 'swr';
import { APIClient } from '../api/client';
import { GetEventRes } from '@/api/event/types';

const fetcher = async (
  key: string,
  lang: string,
): Promise<GetEventRes | undefined> => {
  const result = await APIClient.Event.getEvent({
    params: {
      lang,
    },
  });
  return result.data.result;
};

export const useEventData = (lang: string) => {
  const {
    data: eventData,
    error,
    isLoading,
  } = useSWR<GetEventRes | undefined>(['event', lang], () =>
    fetcher('event', lang),
  );

  return {
    eventData,
    isError: !!error,
    isLoading,
  };
};
