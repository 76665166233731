import LeftContent from './LeftContent';
import RightContent from './RightContent';

const Content = () => {
  return (
    <div className="content-wrapper flex flex-col gap-8 mt-10 lg:mt-[80px] px-5 md:px-[calc(5%)] lg:px-[calc(max(20px,_calc((100%-1400px)/2)))]">
      <div className="text-black text-[25px] md:text-[32px] xl:text-[40px] font-bold font-inter text-center xl:text-left leading-[1.4]">
        Work with us
      </div>
      <div className="flex flex-col xl:flex-row items-start gap-10 xl:gap-[140px]">
        <div className="w-full xl:w-[699px] min-w-[300px] md:flex-1">
          <LeftContent />
        </div>
        <div className="w-full xl:w-[699px] min-w-[300px] md:flex-1">
          <RightContent />
        </div>
      </div>
    </div>
  );
};

export default Content;
