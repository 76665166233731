import { useEventData } from '@/fetcher/useEventData';
import { formatDateRange } from '@/utils';
import { useRouter } from 'next/router';
import React from 'react';

const EventSummary = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const router = useRouter();
  const lang = (router.query.lang as string) ?? 'en';
  const { eventData: events } = useEventData(lang);
  const [isMobile, setIsMobile] = React.useState(false);
  const [containerWidth, setContainerWidth] = React.useState(940);
  const itemMargin = 20;

  React.useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) {
        const peekWidth = 100; // 다음 아이템이 보일 너비
        setContainerWidth(window.innerWidth - peekWidth);
      } else {
        setContainerWidth(940);
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const limitedEvents = events?.content.slice(0, 7);
  const totalSlides = limitedEvents?.length ?? 0;
  const itemWidth = 940; // Width of each item

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  const handleEventClick = (id: number) => {
    const currentLang = router.query.lang;
    router.push(
      `/press-room/event/detail?id=${id}${currentLang ? `&lang=${currentLang}` : ''}`,
    );
  };

  return (
    <div className="w-full px-5 md:px-[calc(5%)] lg:px-[calc(max(20px,_calc((100%-1400px)/2)))] mt-[100px] mb-[60px] md:mb-0 flex-col justify-start items-start gap-5 inline-flex">
      <div className="w-full max-w-[1420px] flex justify-between items-center">
        <div className="text-black text-[28px] md:text-[40px] font-bold font-['Inter'] leading-[1.4]">
          Event
        </div>
        <div className="justify-end items-start gap-3 md:gap-5 flex">
          <button
            onClick={handlePrev}
            className="p-2 md:p-2.5 bg-[#ededed] rounded-[80px] flex items-center justify-center"
          >
            <img
              src="/images/arrow_forward.svg"
              alt="Previous"
              width={16}
              height={16}
              className="md:w-[20px] md:h-[20px]"
            />
          </button>
          <button
            onClick={handleNext}
            className="p-2 md:p-2.5 bg-[#ededed] rounded-[80px] flex items-center justify-center"
          >
            <img
              src="/images/arrow_forward.svg"
              alt="Next"
              width={16}
              height={16}
              className="rotate-180 md:w-[20px] md:h-[20px]"
            />
          </button>
        </div>
      </div>
      <div className="relative w-full">
        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (containerWidth + itemMargin)}px)`,
              width: `${totalSlides * (containerWidth + itemMargin)}px`,
            }}
          >
            {limitedEvents?.map((event, index) => (
              <div
                key={event.id}
                style={{ width: containerWidth }}
                className="h-auto min-h-[132px] p-[30px] bg-[#e2e2e2] rounded-[20px] flex items-start gap-3 md:gap-5 mr-[20px] shrink-0 cursor-pointer hover:bg-[#d9d9d9] transition-colors"
                onClick={() => handleEventClick(event.id!)}
              >
                <div className="flex flex-col md:flex-row items-start gap-4 md:gap-20 w-full">
                  <div className="flex-col justify-center items-start gap-2 md:gap-5 inline-flex flex-1">
                    <div className="text-black text-[20px] md:text-[25px] font-bold font-['Inter'] break-keep">
                      {event.title}
                    </div>
                    <div className="text-black text-sm font-normal font-['Open Sans'] leading-snug">
                      {formatDateRange(event.startDate ?? '', '')}
                    </div>
                  </div>
                  <div className="flex-col justify-center items-start gap-2 inline-flex shrink-0">
                    <div className="flex items-center gap-2">
                      <img
                        src="/images/icon/calendar.svg"
                        alt="Calendar"
                        width={20}
                        height={20}
                      />
                      <span className="text-black text-sm font-normal font-['Open Sans'] leading-snug">
                        {formatDateRange(
                          event.startDate ?? '',
                          event.endDate ?? '',
                        )}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img
                        src="/images/icon/location.svg"
                        alt="Location"
                        width={20}
                        height={20}
                      />
                      <span className="text-black text-sm font-normal font-['Open Sans'] leading-snug">
                        {event.location}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute top-0 right-0 hidden md:block w-[159px] h-full bg-gradient-to-l from-white via-white to-transparent pointer-events-none" />
      </div>
    </div>
  );
};

export default EventSummary;
