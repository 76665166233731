import { useNewsData } from '@/fetcher/useNewsData';
import { formatDateRange } from '@/utils';
import { useRouter } from 'next/router';
import React from 'react';

const NewsSummary = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const router = useRouter();
  const lang = (router.query.lang as string) ?? 'en';
  const { newsData: news } = useNewsData(undefined, lang);

  // Maximum 7 news items to display
  const limitedNews = news?.content.slice(0, 7);
  const totalSlides = limitedNews?.length ?? 0;
  const [isMobile, setIsMobile] = React.useState(false);
  const [containerWidth, setContainerWidth] = React.useState(460);
  const itemGap = 20; // 아이템 간격을 상수로 정의

  React.useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) {
        const peekWidth = 100; // 다음 아이템이 더 많이 보이도록 60px에서 100px로 증가
        setContainerWidth(window.innerWidth - peekWidth);
      } else {
        setContainerWidth(460);
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  const handleNewsClick = (id: number) => {
    const currentLang = router.query.lang;
    router.push(
      `/press-room/news/detail?id=${id}${currentLang ? `&lang=${currentLang}` : ''}`,
    );
  };

  return (
    <div className="w-full px-5 md:px-[calc(5%)] lg:px-[calc(max(20px,_calc((100%-1400px)/2)))] mt-[30px] md:mt-[60px] flex-col justify-start items-start gap-5 md:gap-10 inline-flex">
      <div className="w-full max-w-[1420px] flex justify-between items-center">
        <div className="text-black text-[28px] md:text-[40px] font-bold font-['Inter'] leading-[1.4]">
          News
        </div>
        <div className="justify-end items-start gap-3 md:gap-5 flex">
          <button
            onClick={handlePrev}
            className="p-2 md:p-2.5 bg-[#ededed] rounded-[80px] flex items-center justify-center"
          >
            <img
              src="/images/arrow_forward.svg"
              alt="Previous"
              width={16}
              height={16}
              className="md:w-[20px] md:h-[20px]"
            />
          </button>
          <button
            onClick={handleNext}
            className="p-2 md:p-2.5 bg-[#ededed] rounded-[80px] flex items-center justify-center"
          >
            <img
              src="/images/arrow_forward.svg"
              alt="Next"
              width={16}
              height={16}
              className="rotate-180 md:w-[20px] md:h-[20px]"
            />
          </button>
        </div>
      </div>

      <div className="relative w-full">
        <div
          className={`${
            isMobile ? 'w-full' : 'w-full'
          } overflow-hidden mx-auto`}
        >
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (containerWidth + itemGap)}px)`,
              width: `${totalSlides * (containerWidth + itemGap)}px`,
            }}
          >
            {limitedNews?.map((item, index) => (
              <div
                key={item.id}
                style={{ width: containerWidth }}
                className="h-[300px] md:h-[460px] p-[30px] bg-[#ededed] rounded-[20px] flex-col justify-center items-start gap-[11px] inline-flex mr-[20px] shrink-0 cursor-pointer hover:bg-[#e5e5e5] transition-colors"
                onClick={() => handleNewsClick(item.id!)}
              >
                <div className="w-full flex-col justify-center items-start gap-4 md:gap-[51px] flex">
                  <div className="w-full text-center text-black text-[20px] md:text-[25px] font-bold font-['Inter'] line-clamp-2">
                    {item.title}
                  </div>
                  <div className="w-full text-center text-black text-[16px] md:text-[18px] font-semibold font-['Open Sans'] leading-[24px] md:leading-[26.5px] line-clamp-4">
                    {item.excerpt}
                  </div>
                  <div className="w-full text-center text-black text-[12px] md:text-[14px] font-normal font-['Open Sans'] leading-[20px] md:leading-[22px]">
                    {formatDateRange(
                      item.date ?? '',
                      item.date ?? '',
                    ).toUpperCase()}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute top-0 right-0 hidden md:block w-[159px] h-full bg-gradient-to-l from-white via-white to-transparent pointer-events-none" />
      </div>
    </div>
  );
};

export default NewsSummary;
