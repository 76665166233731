import { useTranslation } from 'react-i18next';
import TranslatedText from '../TranslatedText';

const RightContent = () => {
  const { t } = useTranslation('home');

  return (
    <div className="w-full min-h-[2000px] flex-col justify-start items-end gap-[60px] inline-flex">
      <div className="w-full self-stretch grow shrink basis-0 pb-[60px] flex-col justify-between items-start flex">
        <div className="w-full flex-col justify-start items-start gap-2.5 flex mb-[60px] md:mb-[100px]">
          <div className="h-[42px] p-2.5 bg-[#bc263d] rounded-[15px] justify-center items-center gap-2.5 inline-flex">
            <div className="text-white text-xl font-bold font-['Inter'] leading-[27px]">
              Design
            </div>
          </div>
          <div className="w-full text-black text-[17px] sm:text-[25px] font-bold font-['Inter'] sm:leading-[1.4] break-normal flex">
            <TranslatedText text={t('design.subtitle')} />
          </div>
          <div className="w-full max-w-[579px]">
            <span className="text-black text-[20px] sm:text-xl font-bold font-['Inter'] leading-[27px]">
              <TranslatedText text={t('designSub.subtitle')} />
            </span>
            <span className="text-black text-[20px] sm:text-lg font-normal font-['Open Sans'] leading-[22px]">
              <br />
              <TranslatedText text={t('design.description')} />
            </span>
          </div>
          <div className="w-full max-w-[579px] text-black text-[20px] sm:text-xl font-bold font-['Inter'] leading-[27px]">
            <br />
            Design Your Own SSD with Fadu Inside
          </div>
          <div className="w-full py-2.5 flex flex-wrap justify-start items-start gap-5">
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/memory.svg"
                    alt="Controller"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                controller
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/terminal.svg"
                    alt="Firmware"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                firmware
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex-col justify-start items-start gap-5 flex mb-[60px] md:mb-[100px]">
          <div className="h-[42px] p-2.5 bg-[#bc263d] rounded-[15px] justify-center items-center gap-2.5 inline-flex">
            <div className="text-white text-xl font-bold font-['Inter'] leading-[27px]">
              Build
            </div>
          </div>
          <div className="w-full text-black text-[17px] sm:text-[25px] font-bold font-['Inter'] sm:leading-[1.4]">
            <TranslatedText text={t('build.subtitle')} />
          </div>
          <div className="w-full max-w-[579px]">
            <span className="text-black text-[20px] sm:text-xl font-bold font-['Inter'] leading-[27px]">
              <TranslatedText text={t('build.description')} />
              <br />
            </span>
            <span className="text-black text-[20px] sm:text-lg font-normal font-['Open Sans'] leading-[22px]">
              <br />
              <TranslatedText text={t('build.subDescription')} />
            </span>
          </div>
          <div className="w-full max-w-[579px] text-black text-[20px] sm:text-xl font-bold font-['Inter'] leading-[27px]">
            <br />
            Build Your Own SSD
          </div>
          <div className="w-full py-2.5 flex flex-wrap justify-start items-start gap-5">
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/memory.svg"
                    alt="Controller"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                controller
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/terminal.svg"
                    alt="Firmware"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                firmware
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/developer_board.svg"
                    alt="SSD Design"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                SSD Design
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/receipt_long.svg"
                    alt="BOM"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                BOM
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/call_quality.svg"
                    alt="Support"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                Support
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex-col justify-start items-start gap-5 flex mb-[60px] md:mb-[100px]">
          <div className="h-[42px] p-2.5 bg-[#bc263d] rounded-[15px] justify-center items-center gap-2.5 inline-flex">
            <div className="text-white text-xl font-bold font-['Inter'] leading-[27px]">
              Use or Sell
            </div>
          </div>
          <div className="w-full text-black text-[17px] sm:text-[25px] font-bold font-['Inter'] sm:leading-[1.4]">
            <TranslatedText text={t('useOrSell.subtitle')} />
          </div>
          <div className="w-full max-w-[581px]">
            <span className="text-black text-[20px] sm:text-xl font-bold font-['Inter'] leading-[27px]">
              <TranslatedText text={t('useOrSell.description')} />
              <br />
            </span>
            <span className="text-black text-[20px] sm:text-lg font-normal font-['Open Sans'] leading-[22px]">
              <br />
              <TranslatedText text={t('useOrSell.subDescription')} />
            </span>
          </div>
          <div className="w-full max-w-[579px] text-black text-[20px] sm:text-xl font-bold font-['Inter'] leading-[27px]">
            <br />
            Consign Your Flash to FADU
          </div>
          <div className="w-full py-2.5 flex flex-wrap justify-start items-start gap-5">
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/design_services.svg"
                    alt="Design"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                design
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/instant_mix.svg"
                    alt="Customization"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                customization
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/precision_manufacturing.svg"
                    alt="Manufacturing"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                manufacturing
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/sell.svg"
                    alt="Branding"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                branding
              </div>
            </div>
            <div className="h-[142px] px-2.5 py-[15px] bg-[#ededed] rounded-[10px] flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="w-20 h-20 relative">
                <div className="w-20 h-20 left-0 top-0 absolute flex justify-center items-center">
                  <img
                    src="/images/home/deployed_code.svg"
                    alt="Packaging"
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="text-center text-black text-[11px] font-normal font-['Open Sans'] capitalize leading-snug">
                packaging
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightContent;
