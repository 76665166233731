import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';

dayjs.extend(Duration);

export type DurationFormatterType = {
  hours: number;
  minutes: number;
  seconds: number;
};

export const durationFormatter = (seconds: number): DurationFormatterType => {
  const duration = dayjs.duration(seconds, 'seconds');

  return {
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};

export const durationDeformatter = (format: DurationFormatterType): number => {
  return dayjs.duration(format).asSeconds();
};

export const durationStringify = (seconds: number): string => {
  if (seconds >= 3600 * 24) {
    const duration = dayjs.duration(seconds, 'seconds');

    const hour = duration.days() * 24 + duration.hours();
    const min = duration.minutes();
    const second = duration.seconds();

    return `${hour}:${10 > min ? `0${min}` : min}:${
      10 > second ? `0${second}` : second
    }`;
  } else {
    return dayjs
      .duration(seconds, 'seconds')
      .format(seconds >= 3600 ? 'HH:mm:ss' : 'mm:ss');
  }
};
