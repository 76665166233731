import { useRouter } from 'next/router';
import LocaleLink from '../common/LocaleLink';

const HeroSection = () => {
  const router = useRouter();

  return (
    <div className="relative h-[650px] md:h-[450px] px-5 md:px-[calc(5%)] lg:px-[calc(max(20px,_calc((100%-1400px)/2)))] mt-[30px] md:mt-[100px] mb-[40px] md:mb-[120px]">
      <div className="w-full max-w-[1420px] relative h-full">
        <img
          className="w-full h-[500px] md:h-full rounded-[15px] object-cover"
          src="/images/hero-background.png"
          alt="FADU background"
        />
        <div className="absolute top-0 left-0 h-full w-full flex flex-col justify-start md:justify-center items-center md:items-end gap-3 md:gap-5 p-6 md:p-8 pt-[100px] md:pt-6 pr-4 md:pr-[115px]">
          <div className="flex flex-col items-center md:items-end">
            <div className="text-center md:text-right text-white text-[11px] md:text-[13px] font-bold font-['Open Sans'] leading-[27px]">
              FMS
            </div>
            <div className="max-w-full md:max-w-[1177px] text-center md:text-right text-white text-[20px] md:text-[30px] lg:text-[35px] font-bold font-['Inter'] px-4 md:px-0 leading-[1.4]">
              FADU is a fabless semiconductor company innovating Flash storage
              technology and the Flash storage supply chain
            </div>
          </div>
          <div className="mt-8 md:mt-0 md:relative md:bottom-auto md:left-auto md:right-auto">
            <LocaleLink href={{ pathname: '/market#customer-benefits' }}>
              <button className="px-6 py-3 md:px-4 md:py-2.5 bg-white rounded-[100px] hover:bg-gray-100 transition-colors">
                <span className="text-center text-black text-[15px] md:text-sm font-bold font-['Open Sans'] leading-snug">
                  Learn More
                </span>
              </button>
            </LocaleLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
