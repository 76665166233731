import useSWR from 'swr';
import { APIClient } from '../api/client';
import { PagedNewsResult } from '@/api/news/types';

const fetcher = async (
  key: string,
  tag?: string,
  lang?: string,
  page?: number,
  size?: number,
): Promise<PagedNewsResult> => {
  const result = await APIClient.News.getNews({
    tag,
    params: {
      lang,
      page: page ? page - 1 : 0, // 0-based pagination
      size: size || 5,
    },
  });
  return result.data.result;
};

export const useNewsData = (
  tag?: string,
  lang?: string,
  page: number = 1,
  size: number = 5,
) => {
  const {
    data: newsData,
    error,
    isLoading,
  } = useSWR<PagedNewsResult | undefined>(['news', tag, lang, page, size], () =>
    fetcher('news', tag, lang, page, size),
  );

  return {
    newsData,
    isError: !!error,
    isLoading,
  };
};
